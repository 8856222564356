const devSettings = {
  analyticsSettings: {
    Amplitude: {
      apiKey: '1aec7a3308be0972f179a0056a5a2eaf',
      trackReferrer: true,
      trackGclid: true,
    },
  },
};

const prodSettings = {
  analyticsSettings: {
    Amplitude: {
      apiKey: 'db83adc35ce514e7ae4956b2d56cf2eb',
      trackReferrer: true,
      trackGclid: true,
    },
    'Google Analytics': {
      trackingId: 'UA-93494228-6',
    },
  },
};

export const getSettings = () => {
  return process.env.NODE_ENV === 'production' ? prodSettings : devSettings;
};
