import 'isomorphic-fetch';

import { setupAnalytics } from './src/helpers';

export const onClientEntry = () => {
  setupAnalytics();
};

export const onRouteUpdate = ({ location }) => {
  const pageName = location.pathname === '/' ? 'home' : location.pathname.replace(/\//g, '.').substring(1);

  if (!pageName) return;

  const page = {
    title: document.title,
    referrer: document.referrer,
    path: location.pathname,
    search: location.search,
    url: location.href,
  };

  analytics.page('website', pageName || 'unknown', page);
};
