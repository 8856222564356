import { initAnalytics } from '@okgrow/auto-analytics';
import analytics from './analytics';

import { getSettings } from '../settings';

export const setupAnalytics = () => {
  const { analyticsSettings } = getSettings();

  if (!analyticsSettings) return;

  const settings = {
    analytics,
    integrations: analyticsSettings,
    options: {},
    autorun: false,
  };

  initAnalytics(settings);
};

export const getAmplitudeDeviceId = (slug) => {
  if (typeof window === 'undefined' || !window.amplitude || !window.amplitude.options) return undefined;

  const { deviceId } = window.amplitude.options;

  return deviceId;
};
